import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "field" }
const _hoisted_2 = { class: "control" }
const _hoisted_3 = ["id", "placeholder", "disabled", "readonly", "value", "type", "autofocus", "name"]
const _hoisted_4 = { class: "help is-danger" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", {
      class: _normalizeClass(['label', { 'is-medium': _ctx.$props.medium, 'is-sr-only': _ctx.$props.srOnly }])
    }, _toDisplayString(_ctx.$props.label), 3),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("input", _mergeProps({ id: _ctx.name }, _ctx.$attrs, {
        class: ['input', _ctx.classes],
        placeholder: _ctx.$props.placeholder,
        disabled: _ctx.$props.disabled,
        readonly: _ctx.$props.readonly,
        value: _ctx.inputValue,
        type: _ctx.type,
        autofocus: _ctx.autofocus,
        name: _ctx.name,
        onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleChange && _ctx.handleChange(...args))),
        onBlur: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleBlur && _ctx.handleBlur(...args)))
      }), null, 16, _hoisted_3)
    ]),
    _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.errorMessage), 1)
  ]))
}