
import { computed, defineComponent, PropType } from 'vue'
import { useField } from 'vee-validate'

export default defineComponent({
	name: 'LdInput',
	inheritAttrs: false,
	props: {
		label: {
			type: String as PropType<string>,
			default: '',
		},
		placeholder: {
			type: String as PropType<string>,
			default: '',
		},
		medium: {
			type: Boolean as PropType<boolean>,
			default: false,
		},
		rounded: {
			type: Boolean as PropType<boolean>,
			default: false,
		},
		disabled: {
			type: Boolean as PropType<boolean>,
			default: false,
		},
		readonly: {
			type: Boolean as PropType<boolean>,
			default: false,
		},
		// expected type values: text, password, email, tel
		type: {
			type: String as PropType<string>,
			default: 'text',
		},
		autofocus: {
			type: Boolean as PropType<boolean>,
			default: false,
		},
		name: {
			type: String as PropType<string>,
			default: '',
		},
		value: {
			type: String as PropType<string>,
			default: '',
		},
		srOnly: {
			type: Boolean as PropType<boolean>,
			default: false,
		},
	},
	setup(props) {
		const {
			value: inputValue,
			errorMessage,
			handleBlur,
			handleChange,
		} = useField<string>(props.name, undefined, {
			initialValue: props.value,
		})
		const classes = computed(() => {
			return {
				'is-medium': props.medium,
				'is-rounded': props.rounded,
				'is-danger': !!errorMessage.value,
			}
		})

		return {
			inputValue,
			classes,
			errorMessage,
			handleBlur,
			handleChange,
		}
	},
})
