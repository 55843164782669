export const url = /[a-zA-Z0-9]+([-\.]{1}[a-zA-Z0-9]+)*\.[a-zA-Z]{2,10}(:[0-9]{1,5})?(\/.*)?$/
export const email = /^([a-zA-Z0-9_.+-])+@(([a-zA-Z0-9-])+.)+([a-zA-Z0-9]{2,4})+$/
export const propertyID = /^UA-[0-9]{5,}-[0-9]{1,3}$/
export const linkerDomains = /^[a-zA-Z0-9][a-zA-Z0-9-]+\.[a-zA-Z]{2,6}?$/
// Google Analytics 4
export const measurementId = /^G-[a-zA-Z0-9]+$/
export const apiSecret = /^[a-zA-Z0-9\-\_]+$/
// Facebook CAPI
export const pixelId = /^[0-9]+$/
export const fbCapiAccessToken = /^[a-zA-Z0-9]+$/
