import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0ea1ed7e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "points" }
const _hoisted_3 = { class: "point-index" }
const _hoisted_4 = { class: "point-content" }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = { class: "column is-tablet is-8 input-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_StepHeader = _resolveComponent("StepHeader")!
  const _component_LdInput = _resolveComponent("LdInput")!
  const _component_StepFooter = _resolveComponent("StepFooter")!
  const _component_StepContainer = _resolveComponent("StepContainer")!

  return (_openBlock(), _createBlock(_component_StepContainer, null, {
    default: _withCtx(() => [
      _createVNode(_component_StepHeader, {
        title: _ctx.$t('connection.steps.ga4.title')
      }, null, 8, ["title"]),
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.measurementIdPoints, (item, key, i) => {
            return (_openBlock(), _createElementBlock("div", {
              key: i,
              ref_for: true,
              ref: "listItems",
              class: "point mb-3"
            }, [
              _createElementVNode("div", _hoisted_3, _toDisplayString(i + 1) + ".", 1),
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", {
                  innerHTML: 
								_ctx.$t(`connection.steps.ga4.points.measurementId.${key}`, {
									storeHash: _ctx.storeHash,
								})
							
                }, null, 8, _hoisted_5)
              ])
            ]))
          }), 128))
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_LdInput, {
            placeholder: _ctx.$t('connection.steps.ga4.measurementId.placeholder'),
            "sr-only": "",
            label: _ctx.$t('connection.steps.ga4.measurementId.label'),
            medium: "",
            type: "text",
            name: _ctx.FORM_FIELDS.measurementId
          }, null, 8, ["placeholder", "label", "name"])
        ])
      ]),
      _createVNode(_component_StepFooter, {
        disabled: !_ctx.meta.dirty,
        onNextPage: _ctx.onNextPage
      }, null, 8, ["disabled", "onNextPage"])
    ]),
    _: 1
  }))
}